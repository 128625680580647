import React, { createContext, useContext, useEffect, useState } from "react";

const StorageContext = createContext();

export function StorageProvider({ children }) {
  const [storageDetail, setStorageDetail] = useState({
    user_id: localStorage.getItem("user_id"),
    chat_id: localStorage.getItem("chat_id"),

  });

  useEffect(() => {
    if (storageDetail?.user_id){
      localStorage.setItem("user_id", storageDetail?.user_id);
    }
    else if(storageDetail?.chat_id){
      localStorage.setItem("chat_id", storageDetail?.chat_id);
    }
  }, [storageDetail]);

  return (
    <StorageContext.Provider value={{ storageDetail, setStorageDetail }}>
      {children}
    </StorageContext.Provider>
  );
}

export function useStorageContext() {
  return useContext(StorageContext);
}
