import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import logo from "../assets/icons/Logo.svg";
import Bell from "../assets/icons/Group (5).svg";
import Profile_Avadar from "../assets/icons/profileAvtar.svg";
import settingsIcon from "../assets/icons/Settings 1.svg";
import dashboard from "../assets/icons/dashboard-dashboard.svg";
import youeAI from "../assets/icons/youeAI-dashboard.svg";
import roadmap from "../assets/icons/roadmap-dashboard.svg";
import pathways from "../assets/icons/pathways-dashboard.svg";
import profileIcon from "../assets/icons/profile-dashboard.svg";
import dashboard_s from "../assets/icons/dashboard-dashboard-selected.svg";
import youeAI_s from "../assets/icons/youeAI-dashboard-selected.svg";
import roadmap_s from "../assets/icons/roadmap-dashboard-selected.svg";
import pathways_s from "../assets/icons/pathways-dashboard-selected.svg";
import profileIcon_s from "../assets/icons/profile-dashboard-selected.svg";
import signout from "../assets/icons/signout-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { getMethod, postMethod } from "../../Components/Api'sCall/Apicalling";
import { useStorageContext } from "../../Components/BeforeLogin/Context/storageContext";
import { useStepContext } from "../../Components/BeforeLogin/Context/activeStepContext";
import { useProfileContext } from "../../Components/BeforeLogin/Context/profileSetupContext";
import "../assets/CSS/AppBar.css";
import { useLogoutContext } from "../../Components/BeforeLogin/Context/logoutMessageContext";

const pages = [
  {
    label: "Dashboard",
    icon: dashboard,
    icon_s: dashboard_s,
    href: "/dashboardPhase2",
  },
  { label: "youeAI", icon: youeAI, icon_s: youeAI_s, href: "/youe_ai_page" },
  { label: "Roadmap", icon: roadmap, icon_s: roadmap_s, href: "/road-map" },
  {
    label: "Pathways",
    icon: pathways,
    icon_s: pathways_s,
    href: "/phase2-pathway",
  },
  {
    label: "Profile",
    icon: profileIcon,
    icon_s: profileIcon_s,
    href: "/profile",
  },
];

const protectedPathName = [
  "/dashboardPhase2",
  "/profile",
  "/appbar",
  "/road-map",
  "/account_setting_phase2",
  "/youe_ai_page",
  "/phase2-pathway",
  "/payment/success",
];

function ResponsiveAppBar() {
  const { storageDetail } = useStorageContext();
  const { profileData } = useProfileContext();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { setIsLogout, isLogout } = useLogoutContext();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const Navigate = useNavigate();
  const [userName, setUserName] = useState();
  const [profileImage, setprofileImage] = useState();
  const { step, setStep } = useStepContext();
  const { pathname } = useLocation();
  const [settings, setSettings] = useState([
    { label: "Sign out", icon: signout },
  ]);
  const [activePage, setActivePage] = useState(pathname);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    event.preventDefault();
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page, event) => {
    event.preventDefault();
    if (page?.label === "Roadmap") {
      localStorage.removeItem("current_road");
    }
    Navigate(page.href);
    setAnchorElNav(null);
    setActivePage(page.href);
  };

  const handleCloseUserMenu = (value, e) => {
    if (value.label === "Sign out") OnSubmit();
    else if (value.label === "Account Settings")
      Navigate("/account_setting_phase2");

    setAnchorElUser(null);
  };
  const getuserName = () => {
    const user_id = storageDetail?.user_id;
    if (user_id) {
      getMethod(
        `${process.env.REACT_APP_API_URL_V_TWO}v2/account-settings/username/?id=${user_id}`
      )
        .then((response) => {
          if (response.status === "S") {
            const capitalize = (str) =>
              str.replace(/\b\w/g, (match) => match.toUpperCase());
            const last_name = response?.response?.last_name
              ? response?.response?.last_name[0]
              : "";
            const first_name = response?.response?.first_name
              ? response?.response?.first_name
              : "";
            let fullName = `${capitalize(first_name)} ${capitalize(last_name)}`;
            if (!/^\s*$/.test(fullName)) {
              setUserName(fullName);
              setprofileImage(response?.response?.profile_image);
            }
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getuserName();
    const menu = [
      { label: "Account Settings", icon: settingsIcon },
      { label: "Sign out", icon: signout },
    ];
    if (pathname === "/sign-up") {
      if (step > 9) {
        setSettings(menu);
      } else {
        setSettings([{ label: "Sign out", icon: signout }]);
      }
    } else {
      if (pathname === "/exit") {
        setSettings([{ label: "Sign out", icon: signout }]);
      } else {
        setSettings(menu);
      }
    }

    protectedPathName.includes(pathname) && setActivePage(pathname);
  }, [profileData, pathname]);

  const showProtectedPages = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          gap: "2.5%",
        }}
      >
        {pages.map((page, index) => (
          <Box
            key={page}
            onClick={(event) => handleCloseNavMenu(page, event)}
            sx={{
              my: 2,

              color: `${
                (activePage === "/payment/success" &&
                  page.href === "/dashboardPhase2") ||
                activePage === page.href
                  ? "#FF7228"
                  : "var(--Subtext, #86898E)"
              }`,
              fontFamily: "Inter",
              fontSize: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontStyle: "normal",
              cursor: "pointer",
              fontWeight: "500",
              lineHeight: "normal",
              padding: "6px 10px 6px 10px",
              backgroundColor: `${
                (activePage === "/payment/success" &&
                  page.href === "/dashboardPhase2") ||
                activePage === page.href
                  ? "var(--Highlight-Brand, rgba(255, 114, 40, 0.15))"
                  : "transparent"
              }`,
              borderRadius: "10px",
              position: "relative",
            }}
            id={`${page?.href === "/youe_ai_page" ? "youeaiTab" : "otherTab"}`}
          >
            <img
              src={
                (activePage === "/payment/success" &&
                  page.href === "/dashboardPhase2") ||
                activePage === page.href
                  ? page.icon_s
                  : page?.icon
              }
              style={{ marginRight: "10px" }}
            ></img>
            {page?.label}
            {activePage === "/payment/success" &&
              page.href === "/dashboardPhase2" && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "-50%",
                    left: "50%",
                    transform: "translate(-50%)",
                    width: "30px",
                    height: "4px",
                    flexShrink: 0,
                    borderRadius: "15px 15px 0px 0px",
                    backgroundColor: "var(--Brand-Color, #FF7228)",
                  }}
                ></Box>
              )}
            {activePage === page.href && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-50%",
                  left: "50%",
                  transform: "translate(-50%)",
                  width: "30px",
                  height: "4px",
                  flexShrink: 0,
                  borderRadius: "15px 15px 0px 0px",
                  backgroundColor: "var(--Brand-Color, #FF7228)",
                }}
              ></Box>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  const OnSubmit = () => {
    const user_id = storageDetail?.user_id;
    postMethod(`${process.env.REACT_APP_API_URL_V_TWO}v2/logout/`, {
      user_id: user_id,
    })
      .then((response) => {
        if (response.status === "S") {
          setStep(1);
          Navigate("/sign-in");
          setIsLogout(true);
        } else {
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#F8F8F8",
        borderBottom: "1px solid  #DEDEDE",
      }}
      elevation={0}
    >
      <Container maxWidth="xxl" sx={{ position: "relative" }}>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            noWrap
            component="a"
            sx={{
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "red",
              textDecoration: "none",
              position: "absolute",
              left: 0,
            }}
          >
            <img src={logo}></img>
          </Typography>

          {protectedPathName.includes(pathname) && showProtectedPages()}

          <Box
            sx={{
              flexGrow: 0,
              display: "flex",
              position: "absolute",
              right: 0,
            }}
          >
            <IconButton sx={{ p: 0, mr: "20px" }} disabled>
              <Box
                sx={{
                  display: "flex",
                  width: "40px",
                  height: "40px",
                  padding: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  borderRadius: "10px",
                  background: "var(--Highlight, #F4F5F6)",
                }}
              >
                <img src={Bell}></img>
              </Box>
            </IconButton>
            <Box display={"flex"} onClick={handleOpenUserMenu}>
              {userName && (
                <Box
                  sx={{
                    height: "40px",
                    flexShrink: "0",
                    borderRadius: "10px 0px 0px 10px",
                    backgroundColor: "var(--Highlight, #F4F5F6)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--Maintext, #1B1D21)",
                      display: { xs: "none", md: "block" },
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      paddingLeft: "15px",
                      paddingRight: "20px",
                    }}
                  >
                    {userName}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  width: { xs: "40px", md: "40px" },
                  height: "40px",
                  flexShrink: 0,
                  borderRadius: "15px",
                  border: "1px solid var(--Subcolor, #DEDEDE)",
                  background: `var(--Highlight, #F4F5F6)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={profileImage ? profileImage : Profile_Avadar}
                  style={
                    profileImage
                      ? {
                          width: "100%",
                          height: "100%",

                          borderRadius: "inherit",
                        }
                      : {}
                  }
                ></img>
              </Box>
            </Box>
          </Box>
          <Menu
            className="app-bar-menu"
            id="menu-appbar"
            sx={{
              padding: "10px",
            }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting.label}
                onClick={() => handleCloseUserMenu(setting)}
                sx={{
                  width: {
                    md: "195px",
                    sm: "100px",
                    xs: "100px",
                  },
                  display: `flex`,
                  justifyContent: `${
                    settings?.length > 1 ? "start" : "center"
                  }`,
                }}
              >
                <Box
                  sx={{
                    paddingRight: { md: "15px", sm: "6px", xs: "4px" },
                  }}
                >
                  <img src={setting.icon} className="icon-style-menu"></img>
                </Box>
                <Typography
                  sx={{
                    color: `${
                      setting.label === "Sign out" ? "#FF5345" : "#86898E"
                    }`,
                    fontSize: { md: "16px", sm: "14px", xs: "14px" },
                    fontFamily: "inter",
                    fontWeight: "600",
                  }}
                  textAlign="center"
                >
                  {setting.label}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
