import { Box, Checkbox, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./PaymentLoader.css";
import { Typography } from "antd";
import CustomCircularProgress from "./LoadinSpinner";
import { ReactComponent as CheckIcon } from "../../../assets/icons/PaymentCheckbox.svg";
import { ReactComponent as UncheckedIcon } from "../../../assets/icons/PaymentUnchecked.svg";
import { useNavigate, useParams } from "react-router-dom";
import { postMethod } from "../../../../Components/Api'sCall/Apicalling";
import { useStorageContext } from "../../../../Components/BeforeLogin/Context/storageContext";
import ResponsiveAppBar from "../../../components/AppBar";

const PaymentLoader = () => {
  const [loader, setLoader] = useState([
    {
      checked: false,
      label: "Crafting your Roadmap",
    },
    {
      checked: false,
      label: "Setting-up your Dashboard",
    },
  ]);
  const [isGenerating, setGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isNavigationAllowed, setNavigationAllowed] = useState(false);
  const Navigate = useNavigate();
  const { id } = useParams();

  const { storageDetail } = useStorageContext();
  const user_id = storageDetail?.user_id;

  useEffect(() => {
    if (loader[0].checked) {
      setTimeout(() => {
        let loaderStatus = [...loader];
        loaderStatus[1].checked = true;
        setLoader(loaderStatus);
        setNavigationAllowed(true);
      }, 2000);
    }
  }, [loader]);

  useEffect(() => {
    generateRoadmap();
  }, []);

  useEffect(() => {
    if (isNavigationAllowed) {
      setTimeout(() => {
        Navigate("/dashboardPhase2");
      }, 1000);
    }
  }, [isNavigationAllowed]);

  const generateRoadmap = () => {
    let pathway_id = localStorage.getItem("pathway_id_roadmap");
    if (pathway_id) {
      postMethod(
        `${process.env.REACT_APP_API_URL_V_TWO}v2/roadmap/create-roadmap/`,
        {
          user_id,
          pathway_id: pathway_id,
        }
      )
        .then((response) => {
          if (response?.status === "S") {
            let updatedLoad = [...loader];
            updatedLoad[0].checked = true;
            setLoader(updatedLoad);

            localStorage.removeItem("pathway_id_roadmap");
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <Dialog
        open={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: "411px",
              maxWidth: "700px",
            },
          },

          "& .MuiDialog-paper": {
            width: "580px",
            height: "411px",
            borderRadius: "20px",
            border: "1px solid #DEDEDE",
            boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.05)",
            margin: 0,
          },
          "& .MuiDialogContent-root": {
            padding: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
          },
          "& .MuiDialogContent-root::-webkit-scrollbar": {
            display: "none",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <DialogContent>
          <Box className="loader-continous"></Box>
          <Box
            sx={{
              borderRadius: "15px",
              border: "1px solid #DEDEDE",
              display: "flex",
              padding: "20px",
              alignItems: "start",
              flexDirection: "column",
              backdropFilter: "blur(7.5px)",
            }}
          >
            {loader.map((load) => {
              return (
                <Box className="loader-payment">
                  <Checkbox
                    checked={load.checked}
                    icon={<UncheckedIcon />}
                    checkedIcon={<CheckIcon />}
                    size="small"
                  />
                  <Box className="loader-label">{load.label}</Box>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentLoader;
