import axios from "axios";

export const deleteTrip = async (endPoint, request) => {
  let obj = {};
  await axios
    .delete(endPoint, request)
    .then((response) => {
      obj["status"] = "S";
      obj["response"] = response.data;
    })
    .catch((error) => {
      obj["status"] = "F";
      obj["errorData"] = error;
    });
  return obj;
};

export const patchTripdata = async (endPoint, request) => {
  let obj = {};
  await axios
    .patch(endPoint, request)
    .then((response) => {
      obj["status"] = "S";
      obj["response"] = response.data;
    })
    .catch((error) => {
      obj["status"] = "F";
      obj["errorData"] = error;
    });
  return obj;
};

export const postMethod = async (endPoint, request) => {
  let obj = {};
  await axios
    .post(endPoint, request)
    .then((response) => {
      obj["status"] = "S";
      obj["response"] = response.data;
    })
    .catch((error) => {
      obj["status"] = "F";
      obj["errorData"] = error;
    });
  return obj;
};

export const getMethod = async (endPoint) => {
  let obj = {};
  await axios
    .get(endPoint)
    .then((response) => {
      obj["status"] = "S";
      obj["response"] = response.data;
    })
    .catch((error) => {
      obj["status"] = "F";
      obj["errorData"] = error;
    });
  return obj;
};
