import React from "react";
import { EmailProvider } from "../BeforeLogin/Context/loginContext";
import { StepProvider } from "../BeforeLogin/Context/activeStepContext";
import { ProfileProvider } from "../BeforeLogin/Context/profileSetupContext";
import { LoadingProvider } from "../BeforeLogin/Context/loadingContext";
import { LogoutProvider } from "../BeforeLogin/Context/logoutMessageContext";
import { StorageProvider } from "../BeforeLogin/Context/storageContext";
import { LinkedInSignUpStateProvider } from "../BeforeLogin/Context/linkedInSignUpStateContext";
import { LinkedinProvider } from "../BeforeLogin/Context/isLinkedIn";

function RootProviders({ children }) {
  return (
    <LoadingProvider>
      <StorageProvider>
        <EmailProvider>
          <LinkedinProvider>
          <LinkedInSignUpStateProvider>
          <StepProvider>
            <ProfileProvider>
              {/* <ActiveNameProvider> */}
                <LogoutProvider>
                  {/* <ProfileHeaderProvider> */}
                    {/* <ActiveTabProvi+++++++++der> */}
                      {/* <BasicDataProvider>{children}</BasicDataProvider> */}
                      {children}
                    {/* </ActiveTabProvider> */}
                  {/* </ProfileHeaderProvider> */}
                </LogoutProvider>
              {/* </ActiveNameProvider> */}
            </ProfileProvider>
          </StepProvider>
          </LinkedInSignUpStateProvider>
          </LinkedinProvider>
        </EmailProvider>
      </StorageProvider>
    </LoadingProvider>
  );
}

export default RootProviders;
